html,body {
  width: 100%;
  height: 100%;
  //font-family: 'Roboto', sans-serif;
font-family: 'kreon', serif;
}

ul {margin:0;padding:0;}

.slideout-menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: 256px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: none;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#520075+9,520075+36,520075+36,520075+36,520075+36,910091+100 */
background: rgb(82,0,117); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(82,0,117,1) 9%, rgba(82,0,117,1) 36%, rgba(82,0,117,1) 36%, rgba(82,0,117,1) 36%, rgba(82,0,117,1) 36%, rgba(145,0,145,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(82,0,117,1) 9%,rgba(82,0,117,1) 36%,rgba(82,0,117,1) 36%,rgba(82,0,117,1) 36%,rgba(82,0,117,1) 36%,rgba(145,0,145,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(82,0,117,1) 9%,rgba(82,0,117,1) 36%,rgba(82,0,117,1) 36%,rgba(82,0,117,1) 36%,rgba(82,0,117,1) 36%,rgba(145,0,145,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#520075', endColorstr='#910091',GradientType=0 ); /* IE6-9 */


}

.slideout-panel {
  position:relative;
  z-index: 1;
  will-change: transform;
  background: #fff;

}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow:hidden;
}

.slideout-open .slideout-menu {
  display: block;
}
.slideout-menu{color:#fff;padding:30px 20px;
  ul {margin-top: 80px;
    li {list-style: none;margin-bottom: 5px;text-align: center;}
    a{font-size: $font-size-base * 1.2;color:#FFF;padding:5px;display: block;font-weight: 300;text-transform: uppercase;}
    a:hover{text-decoration:underline;font-weight: 500;} 
  }  
}
.menu-bottom {margin:0 auto;position:absolute;left:18%;bottom: 5%;text-align: center;
ul.redes{padding:0 5px;text-align: center;margin: 0 auto;
li {display: inline-block;margin-right: 2px;text-align: center;}
li span[class^="icon"]{font-size: $font-size-base * 1.9;}
}
}
.menu-btn {position:absolute;top:30px;left:20px;z-index: 9999;}
.menu-line {
    display: block;
    width: 30px;
    height: 6px;
    margin-top: 5px;
    margin-right: auto;
    margin-left: auto;
    background-color: $brand-primary;
}



.breadcrumbs {font-size: $font-size-base * 1.1;padding:8px 0;color:#FFF;text-align: center;width: 100%;font-weight: 300;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#520075+9,520075+36,520075+36,520075+36,520075+36,910091+100 */
background: rgb(82,0,117); /* Old browsers */
background: -moz-linear-gradient(left,  rgba(82,0,117,1) 9%, rgba(82,0,117,1) 36%, rgba(82,0,117,1) 36%, rgba(82,0,117,1) 36%, rgba(82,0,117,1) 36%, rgba(145,0,145,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(82,0,117,1) 9%,rgba(82,0,117,1) 36%,rgba(82,0,117,1) 36%,rgba(82,0,117,1) 36%,rgba(82,0,117,1) 36%,rgba(145,0,145,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(82,0,117,1) 9%,rgba(82,0,117,1) 36%,rgba(82,0,117,1) 36%,rgba(82,0,117,1) 36%,rgba(82,0,117,1) 36%,rgba(145,0,145,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#520075', endColorstr='#910091',GradientType=1 ); /* IE6-9 */

span {color:#FFF;padding:0 5px;}
a span {color:#FFF;}  
}

.footer-contacto{background: #333;padding:10px 0;
p{color:$brand-primary;font-weight: 600;text-align: center;}
}
.footer{width: 100%;background-color: #F9F9F9;padding:40px 0 20px 0;
h4{color:$brand-primary;font-weight: 300;text-transform:uppercase;}
  ul{margin:0;padding:0;margin-bottom: 20px;
    li{list-style: none;font-weight: 300;text-transform:uppercase;margin-bottom: 3px;}
  }
}

.footer-direccion{
  p{font-weight:300;text-transform: uppercase;margin-bottom: 3px;}
  p.horario{padding:15px 0;margin-top: 20px;}
  span.label{color:$brand-primary;margin-right: 8px;}
}

.footer-contactos{margin-top: 20px;}

.pie{width:100%; background-color: #E9E9E9;padding:15px 0;text-align: center;
p{text-transform: uppercase;color:#333;font-weight: 300;font-size: $font-size-base;margin:0;}
span.pielink{margin:0 8px;}
}