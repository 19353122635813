.menu-contacto-top{position: absolute;width:30%;top:23px;right:20px;z-index: 9999;text-align: right;
p{background-color:rgba(255,255,255,.8);color:#fff;display: inline-block;border-radius: 4px;padding:5px 14px;font-weight: 300;}
span[class^="icon-"]{font-size: $font-size-base * 1.5;margin-right: 8px;color:$brand-primary;}
a {color:$brand-primary;font-size: $font-size-base * 1.5;}
}
.block-item{width: 100%;height: 550px;
.block-item-texto{position:absolute;top:25%;width:100%;padding:0 250px;}
h1{font-size: $font-size-base * 5;line-height:$font-size-base * 4.5;font-weight:700;color:#FFF;text-align: center;text-shadow: 1px 1px 2px rgba(0,1,0,.7);}
h2{font-size: $font-size-base * 3;line-height:$font-size-base * 2.5;font-weight:400;color:#FFF;text-align: center;text-shadow: 1px 1px 2px rgba(0,1,0,.7);margin-bottom: 30px;}
p{width:50%;margin:0 auto;text-align: center;margin-top: 60px;color:#fff;font-size: $font-size-base * 1.4;border:3px solid #fff;padding:20px;font-weight: 700;}
a{color:#fff;}
a:hover{text-decoration: none;}
a:hover p{background-color: #fff;color:$brand-primary;} 

}

@media (max-width:1024px){
.menu-contacto-top{width:40%;}	
.block-item{height: 500px;
.block-item-texto{top:25%;padding:0 80px;}
h1{font-size: $font-size-base * 4;line-height:$font-size-base * 3.8;}
h2{font-size: $font-size-base * 2;line-height:$font-size-base * 1.9;margin-bottom: 20px;}

p{width:75%;font-size: $font-size-base * 1.1}
}

}
@media (max-width:768px){
.menu-contacto-top{width:55%;}	
.block-item{height: 450px;
.block-item-texto{top:18%;padding:0 30px;}
h1{font-size: $font-size-base * 3.5;}
h2{font-size: $font-size-base * 2.4;line-height:$font-size-base * 1.9;}
p{width:96%;font-size: $font-size-base * 1;}
}

}

#fp-nav ul li a span, .fp-slidesNav ul li a span {background:$brand-primary!important;}
.block-item-show{text-align: center;height: 100%;min-height: 100%;   
h2{font-weight: 700;margin-bottom: 90px;padding: 0 200px;color:$brand-primary;}
}


.servicios{margin-top: 45px;margin-bottom: 45px;
.servicios-intro{width: 70%;margin:0 auto;text-align: center;
p{margin-top:20px;font-size: $font-size-base * 1.6;font-weight: 300;color:#666;}
}
.servicios-items{width: 95%;margin: 0 auto;margin-top: 40px;}
}

@media (max-width:768px){
.servicios{width: 95%;margin: 0 auto;margin-top: 25px;margin-bottom: 25px;}
.servicios-intro{width: 95%;}
}

.width-33{width: 33.3%}
.width-25{width: 25%}
.width-66{width: 66%}
.width-50{width: 50%}
.width-100{width: 100%}

ul.home-items{margin: 0;padding: 0;
li{display:inline-block;float:left;list-style:none;}
	.block-item-home{font-weight: 300;padding:40px;min-height: 650px;
		.block-item-home-img{height: 235px}
		img {
          -webkit-filter: opacity(85%); /* Chrome, Safari, Opera */
          filter: opacity(85%);
		   }
		 a img:hover{
		-webkit-filter:opacity(45%);
		transition: filter .40s ease-in-out;
    	moz-transition: filter .40s ease-in-out;
       -webkit-transition: filter .40s ease-in-out;
}  
		.block-item-home-texto{margin-top: 15px;text-align: center;
		h2{font-weight: 400;font-size: $font-size-base * 1.8;padding:10px 35px;color:$brand-primary;}
		p{font-size: $font-size-base * 1.3;color:#666;}
		} 	
		
	}
}
@media (min-width: 1280px){
ul.home-items{
.block-item-home{min-height: 550px;}
}	
}

@media (max-width: 1024px){
ul.home-items{
li{width: 50%;}
.block-item-home{padding:30px;min-height: 600px;}
.block-item-home-img{height: 200px}
}	
}

@media (max-width:768px){
ul.home-items{
li{width: 100%;}
.block-item-home{font-weight: 100;padding:40px;min-height: 350px;}
.block-item-home-img{height: 250px}
}

}

.servicios:after {
    content: '';
    display: block;
    clear: both;
}

.home-banner{width: 100%;background-color:$brand-primary;margin:40px 0;position: relative;padding:40px 100px;min-height: 330px;
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#520075+9,520075+36,520075+36,520075+36,520075+36,910091+100 */
background: rgb(82,0,117); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(82,0,117,1) 9%, rgba(82,0,117,1) 36%, rgba(82,0,117,1) 36%, rgba(82,0,117,1) 36%, rgba(82,0,117,1) 36%, rgba(145,0,145,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(82,0,117,1) 9%,rgba(82,0,117,1) 36%,rgba(82,0,117,1) 36%,rgba(82,0,117,1) 36%,rgba(82,0,117,1) 36%,rgba(145,0,145,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(82,0,117,1) 9%,rgba(82,0,117,1) 36%,rgba(82,0,117,1) 36%,rgba(82,0,117,1) 36%,rgba(82,0,117,1) 36%,rgba(145,0,145,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#520075', endColorstr='#910091',GradientType=0 ); /* IE6-9 */

.banner-img {width:33%;margin-top:-9%;float: left;}
.banner-texto{width:66%;float: left;text-align: center;
h2{color:#fff;font-size: $font-size-base * 2.2;font-weight: 400;}
p{width:55%;margin:0 auto;text-align: center;margin-top: 30px;color:#fff;font-size: $font-size-base * 1.3;border:3px solid #fff;padding:20px;}
a:hover p{background-color: #fff;color:$brand-primary;} 
}
}

@media (max-width:1024px){
.home-banner{padding:25px 40px;	
	.banner-img {width:25%;}
	.banner-texto{width:75%;
	h2{font-size: $font-size-base * 2;}	
	}

}

}

@media (max-width:768px){
.home-banner{padding:25px 40px;	
	.banner-img {display: none;}
	.banner-texto{width:95%;float:none;
	h2{font-size: $font-size-base * 2;}	
	}

}

}
.home-partners{width: 100%;
h3{width:55%;margin:0 auto;font-size: $font-size-base * 1.9;text-align: center;font-weight: 400;color:$brand-primary;}
ul{margin: 0 auto;text-align: center;margin-top: 10px;
li{display:inline-block;width: 24%;padding:10px;}
img{opacity: .3;}
}

}
.home-reviews{width: 100%;background-color: #f9f9f9;padding: 50px;text-align: center;margin-top:40px;
ul{margin: 0 auto;text-align: center;
	li{display: inline-block;list-style: none;}
	span[class^="icon-"]{color:$brand-primary;font-size: $font-size-base * 1.3;}
}	
h3{text-align: center;font-size: $font-size-base * 1.9;color: $brand-primary;margin-bottom: 30px;}
p{font-size: $font-size-base * 1.3;line-height: $font-size-base * 1.8;font-weight: 300;color: #444;padding:0 10px;}
p.review-autor{margin-top: 30px;font-weight: 400;color:$brand-primary;font-size: $font-size-base * 1.5;margin-bottom: 2px;}
span.review-trabajo{color:#999;font-weight: 300;display: block;}

}

@media (max-width:768px){
.home-partners{
h3{width:85%;}
}	
.home-reviews{padding: 30px 20px;
span.review-trabajo{margin-bottom: 30px;}
}

}

.home-redes{color:#fff;width: 100%; padding:60px;text-align: center;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#520075+9,520075+36,520075+36,520075+36,520075+36,910091+100 */
background: rgb(82,0,117); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(82,0,117,1) 9%, rgba(82,0,117,1) 36%, rgba(82,0,117,1) 36%, rgba(82,0,117,1) 36%, rgba(82,0,117,1) 36%, rgba(145,0,145,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(82,0,117,1) 9%,rgba(82,0,117,1) 36%,rgba(82,0,117,1) 36%,rgba(82,0,117,1) 36%,rgba(82,0,117,1) 36%,rgba(145,0,145,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(82,0,117,1) 9%,rgba(82,0,117,1) 36%,rgba(82,0,117,1) 36%,rgba(82,0,117,1) 36%,rgba(82,0,117,1) 36%,rgba(145,0,145,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#520075', endColorstr='#910091',GradientType=0 ); /* IE6-9 */

	h3{font-size: $font-size-base * 2.3;}
	p{font-size: $font-size-base * 1.4;}
	ul{margin:0;padding:0;text-align: center;margin: 0 auto;margin-top: 20px;
	li {display: inline-block;margin-right: 20px;}
	li span[class^="icon-"]{color:#fff;font-size: $font-size-base * 3;}	
	}
}


@media (max-width:768px){
.home-redes{padding: 60px 15px;}
}	