.top-pagina{width: 50%;margin: 0 auto;padding-top: 2px;z-index: 99999;}

.header-page{width:100%;padding:15px 0;
.header-page-texto{position:absolute;width:100%;}
}

@media (max-width:768px){
.top-pagina{width: 60%;padding-top: 8px;padding-left: 20px;}
.header-page{min-height:50px;margin-top: 20px;width: 100%;
	h1{font-size: $font-size-base * 1;font-weight:600;color:#fff;text-align: center;}
}
}
.entrada-pagina{width: 66%;margin: 0 auto;
h1{text-align: center;color:$brand-primary;}
p{text-align: center;font-size: $font-size-base * 1.6;font-weight: 300;color:#666;}
}

ul.masonry-items{width: 100%;margin:0;padding: 0;min-width: 100%;margin-top: 20px;

	li{list-style: none;margin:0;padding: 0;float: left;}
	li.masonry-item-block{padding:20px;margin-bottom: 30px;}
	li.masonry-width-25{width: 25%;min-width: 25%;}
	li.masonry-width-33{width: 33.3333%;min-width: 33.3333%;}
	li.masonry-width-66{width: 66.666%;min-width: 66.666%;}
	li.masonry-width-50{width: 50%;min-width: 50%;}

}

.block-page{width: 100%;padding:20px;border:1px solid rgba(75,75,75,.1);border-radius: 5px;text-align: center;
		
.block-page-img{height: 300px;min-height: 300px;}
h2{font-size: $font-size-base * 1.9;font-weight: 300;color: $brand-primary;margin-top: 10px;padding: 0;}
p{margin-top:20px;font-weight: 300;border-bottom:1px solid rgba(0,0,0,.1);padding:5px 8px;color:#999;}
 

}
@media (max-width:768px){
ul.masonry-items{
	li.masonry-width-25,li.masonry-width-33,li.masonry-width-50{width: 100%;}
}
} 

.banner{width: 100%;height: 430px;min-height: 430px;
.bloque-banner{position: absolute;top:38%;width:100%;margin: 0 auto;text-align: center;
p{border:3px solid #fff;margin:0 auto;text-align: center;color:#fff;display: inline-block;padding:15px 25px;text-transform: uppercase;font-size: $font-size-base * 1.2;}
a{color:#fff;text-decoration: none;}	
}
}

.formulario{width: 75%;margin:0 auto;margin-top: 60px;
h3{color:$brand-primary;font-weight: 300;text-transform: uppercase;text-align: center;}
span.label-contact{font-weight: 300;text-transform: uppercase;font-size: $font-size-base * 1.1;}
input.wpcf7-form-control {margin-top:15px;margin-bottom:15px;padding:12px;color:#555; width: 100%;background-color: #F9F9F9;border:1px dotted #CCC;}
textarea.wpcf7-form-control {color:#222; padding:12px;width: 100%;margin-top:10px;margin-bottom:10px;background-color: #F9F9F9;border:1px dotted #CCC;height: 200px;}
input.wpcf7-form-control[type="submit"] {margin-top: 30px; background-color:#fff;color:$brand-primary;padding:15px;border: none; width: 100%;border:2px solid $brand-primary;font-size:$font-size-base * 1.4;font-weight: 300;text-transform: uppercase;}
input.wpcf7-captchar {width: 50%;}
@media (max-width: 768px ) {
	input.wpcf7-captchar {width: 80%;float: left;}
	input.wpcf7-form-control[type="submit"] {width: 100%;}
}
div.wpcf7-response-output.wpcf7-display-none.wpcf7-mail-sent-ok {font-weight: 900;font-size: $font-size-base * 1.2;}
}

@media (max-width:768px){
.formulario{width: 90%;}	
}

#map{width: 80%;margin:0 auto;text-align:center;height: 420px;margin-top:60px;margin-bottom: 60px;}
@media (max-width:768px){
#map{height: 350px;width: 90%;}	
}

ul.page-numbers {margin: 0 auto;text-align:center;width: 50%;margin-top:20px;margin-bottom: 20px;}
ul.page-numbers li {display:inline-block;margin-right: 15px;font-size: $font-size-base * 1.3;}
ul.page-numbers li a {color:$brand-primary;}	